import { NoMatch } from 'components'
import PromoterProvider from 'contexts/promoterContext'
import { PromoterLayout } from 'layout'
import {
  DashboardPage,
  EventCouter,
  EventDetails,
  EventList,
  EventStaff,
  EventStaffDetails,
  LoginPage,
} from 'pages/promoter'
import { BatchUserReport } from 'pages/promoter/BatchUserReport'
import { DiscountCoupons } from 'pages/promoter/DiscountCoupons'
import { GeneralReport } from 'pages/promoter/GeneralReport'
import { GeneralReportPerPOS } from 'pages/promoter/GeneralReportPerPOS'
import { GeneralReportPerUser } from 'pages/promoter/GeneralReportPerUser'
import { NewProducer } from 'pages/promoter/NewProducer'
import { ParticipantsList } from 'pages/promoter/ParticipantsList'
import { Producer } from 'pages/promoter/Producer'
import { Producers } from 'pages/promoter/Producers'
import { RequestWithdrawal } from 'pages/promoter/RequestWithdrawal'
import { StaffMemberSalesList } from 'pages/promoter/StaffMemberSalesList'
import { TransactionList } from 'pages/promoter/TransactionList'
import { Withdrawal } from 'pages/promoter/Withdrawal'
import { CreateEventPage } from 'pages/promoter/event/create-event'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

const ProtectedRoute = () => {
  const location = useLocation()
  const promoter = localStorage.getItem('promoter')
  const token = localStorage.getItem('promoter_token')

  if (!token && !promoter) {
    return (
      <Navigate to="/organizador/login" replace state={{ from: location }} />
    )
  }

  return (
    <PromoterLayout>
      <Outlet />
    </PromoterLayout>
  )
}

const PromoterRouter = () => {
  return (
    <PromoterProvider>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route index element={<EventList />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="eventos" element={<EventList />} />
          <Route path="eventos/novo" element={<CreateEventPage />} />
          <Route path="eventos/:eventId" element={<EventDetails />} />
          <Route path="eventos/:eventId/contador" element={<EventCouter />} />
          <Route path="eventos/:eventId/staff" element={<EventStaff />} />
          <Route
            path="eventos/:eventId/pedidos"
            element={<TransactionList />}
          />
          <Route
            path="eventos/:eventId/participantes"
            element={<ParticipantsList />}
          />
          <Route
            path="eventos/:eventId/vendas-por-setor"
            element={<EventStaff />}
          />
          <Route
            path="eventos/:eventId/cupons-de-desconto"
            element={<DiscountCoupons />}
          />
          <Route
            path="eventos/:eventId/solicitacao-de-saque"
            element={<RequestWithdrawal />}
          />
          <Route
            path="eventos/:eventId/solicitacao-de-saque/:withdrawalId"
            element={<Withdrawal />}
          />
          <Route
            path="eventos/:eventId/relatorios/geral"
            element={<GeneralReport />}
          />
          <Route
            path="eventos/:eventId/relatorios/usuario"
            element={<GeneralReportPerUser />}
          />
          <Route
            path="eventos/:eventId/relatorios/pdv"
            element={<GeneralReportPerPOS />}
          />
          <Route
            path="eventos/:eventId/relatorios/usuario-lote"
            element={<BatchUserReport />}
          />
          <Route
            path="eventos/:eventId/staff/:userStaffId"
            element={<EventStaffDetails />}
          />
          <Route
            path="eventos/:eventId/staff/:userStaffId/lista-vendas"
            element={<StaffMemberSalesList />}
          />

          <Route path="produtoras" element={<Producers />} />
          <Route path="produtoras/:producerId" element={<Producer />} />
          <Route path="produtoras/nova" element={<NewProducer />} />
        </Route>

        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </PromoterProvider>
  )
}

export default PromoterRouter
